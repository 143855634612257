import React, {useEffect, useRef, useState} from "react";
import DataAPI from "../apis/DataAPI";
import {Link, useParams, redirect} from "react-router-dom";
import "../assets/css/style.css";
import Wrapper from "../components/Wrapper";
import {Item, Search, Section} from "../components/Sections/Section";
import {FiSearch} from "react-icons/fi";
import {RxCross2} from "react-icons/rx";
import IconSection from "../components/Sections/Icons";
import SafeHtmlComponent from "../components/SafeHtmlComponent";
import IconBanner from "../components/Sections/IconBanner";
import BannerChristmas from "../components/Banner/BannerChristmas";

function SectionPage() {
  const [sections, setSections] = useState<Section[] | []>([]);
  const [currentSection, setCurrentSection] = useState<Section>();
  const [questions, setQuestions] = useState<Section[] | []>([]);
  const [filter, setFilter] = useState<Search[] | []>([]);
  const [search, setSearch] = useState<string>("");
  const {section} = useParams<{section: string}>();
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await DataAPI.categories();
      if (data.status === "done") {
        const sortedSections = data.data.sort((a, b) => a.order - b.order);
        setSections(sortedSections);
        const current = data.data.find((s: Section) => s.id === section);
        if (current) {
          setCurrentSection(current);
        } else {
          window.location.href = "/404";
        }
      }
    };
    fetchData();
  }, [section]);

  useEffect(() => {
    const fetchData = async () => {
      if (section) {
        const data = await DataAPI.findByCategory(section);
        setQuestions(data.data);
      }
    };
    fetchData();
  }, [section]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    setSearch(e.target.value);
    typingTimeoutRef.current = setTimeout(() => {
      triggerSearch(e.target.value);
    }, 2000);
  };

  const triggerSearch = async (value: string) => {
    try {
      const response = await DataAPI.search(value);
      setFilter(response.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      triggerSearch(search);
    }
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  const handleSearchReset = () => {
    setSearch("");
    setFilter([]);
  };

  return (
    <div>
      <Wrapper>
        <div className="container mx-auto p-8 pb-28">
          <h1 className="text-4xl mb-6 text-bblue text-center font-walsheim-bold">
            Comment pouvons-nous vous aider ?
          </h1>
          <BannerChristmas />
          <div className="search mb-20 flex items-center w-[60%] sm:w-[90%] md:w-[90%] lg:w-[60%] mx-auto relative">
            <Search
              className="border p-5 w-full pr-10 text-lg"
              value={search ? search : ""}
              placeholder="Rechercher"
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <button className="p-2 absolute top-1/2 transform -translate-y-1/2 right-2">
              {search ? (
                <div onClick={handleSearchReset}>
                  <RxCross2 height={70} width={70} color="#0000ff" size={30} />
                </div>
              ) : (
                <FiSearch height={70} width={70} color="#0000ff" size={30} />
              )}
            </button>
          </div>
          {search === "" ? (
            <div className="flex gap-20">
              <div className="w-[33.3%] flex-col hidden sm:flex">
                <ul>
                  {sections.map((category, index) => (
                    <Link to={`/${category.id}`} key={index}>
                      <div className="border-section">
                        <Item
                          $selected={section === category.id}
                          className="p-5 flex items-center">
                          <div className="mr-4">
                            <IconSection
                              icon={category.id}
                              color={
                                section === category.id ? "#ffffff" : "#0000ff"
                              }
                            />
                          </div>
                          <h2
                            className={`text-xl mb-2 ${
                              section === category.id
                                ? "text-white"
                                : "text-bblue"
                            }`}>
                            {category.title}
                          </h2>
                        </Item>
                      </div>
                    </Link>
                  ))}
                </ul>
              </div>
              <div className="md:w-[66.6%] w-full">
                <div className="mb-5 text-bblue">
                  <p>
                    <Link to={"/"}>{"Faq > "}</Link>
                    <span className="font-bold">{currentSection?.title}</span>
                  </p>
                </div>
                <div className="mb-10">
                  <p className="text-bpurple">{currentSection?.description}</p>
                </div>
                <div className="flex flex-col gap-5">
                  {questions.map((item, index) => {
                    if (item.visible === false) return null;
                    return (
                      <Link to={`/${section}/${item.id}`} key={index}>
                        <p className="text-xl text-bpurple hover:underline">
                          {item.title}
                        </p>
                      </Link>
                    );
                  })}
                </div>
                {currentSection && currentSection.content && (
                  <div className="mb-10 mt-10">
                    <SafeHtmlComponent content={currentSection?.content} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <h2 className="ml-4 text-bblue mb-5 text-xl font-walsheim-bold">
                {"Recherche : "}
                <span className="font-walsheim-regular">{search}</span>
              </h2>
              <div className="">
                {filter.length === 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <p className="ml-4">Aucun résultat pour le moment.</p>
                  </div>
                )}
                {filter.map((article: Search) => (
                  <Link
                    to={`/${article.category}/${article.id}`}
                    key={article.id}>
                    <div className="border p-6 rounded-md">
                      <h2 className="text-bblue mb-2 text-xl font-walsheim-bold">
                        {article.title}
                      </h2>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
}

export default SectionPage;
